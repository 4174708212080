<template>
    <div>
      <b-container class="mt-5 mb-5">
        <b-dropdown dropleft variant="outline-dark" text="Opties" class="float-right">
            <b-dropdown-item :to="'/manage/events/' + this.$route.params.id + '/orders'">Bestellingen bekijken</b-dropdown-item>
            <b-dropdown-item :to="'/manage/events/' + this.$route.params.id + '/orders/tickets'">Tickets bekijken</b-dropdown-item>
            <!--
            <b-dropdown-divider />
            <b-dropdown-item href="#">Bestellingen exporteren</b-dropdown-item>
            <b-dropdown-item href="#">Tickets exporteren</b-dropdown-item>
            -->
        </b-dropdown>
        <h1 class="fs-4 font-weight-bold mb-4">Bestellingen {{ event.name }}</h1>
        
        <b-card class="mb-3" no-body>
            <b-card-header>
                <div class="float-sm-left">
                    Aantal gevonden: {{ orders.length }}, waarvan actief: {{ ordersActive.length }}
                </div>
                <div class="float-sm-right">
                    <b-button @click="exportOrders" variant="white" size="sm">
                        <font-awesome-icon icon="file-export" class="mr-1" />
                        Exporteren
                    </b-button>
                </div>
            </b-card-header>
            <b-card-body>
                <div v-if="orders.length <= 0" class="text-center text-muted mt-4 mb-4">
                    <font-awesome-icon icon="shopping-basket" class="mb-2" size="2x" />
                    <div class="text-dark fs-5">Geen bestellingen gevonden</div>
                </div>
                <div v-else>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr class="text-muted">
                                    <th>Bestelnr.</th>
                                    <th>Klant</th>
                                    <th>Kosten</th>
                                    <th>Status</th>
                                    <th>Tickets</th>
                                    <th>Datum</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order,key) in orders" :key="key" @click="goToDetail(order)" class="cursor-pointer">
                                    <td>{{ order.order_id }}</td>
                                    <td>{{ order.firstname }} {{ order.lastname }}</td>
                                    <td>{{ order.total_price|toCurrency }}</td>
                                    <td>
                                        <template v-if="order.canceled_at">
                                            <b-badge variant="danger" class="bg-danger">
                                            Geannuleerd
                                            </b-badge>
                                        </template>
                                        <template v-else>
                                            <template v-if="order.payment_status == 'paid'">
                                                <b-badge variant="success" class="bg-success">
                                                    Betaald
                                                </b-badge>
                                            </template>
                                            <template v-if="order.payment_status == 'open' || order.payment_status == 'pending' || order.payment_status == 'created'">
                                                <b-badge variant="warning" class="bg-warning">
                                                    Open
                                                </b-badge>
                                            </template>
                                            <template v-if="order.payment_status == 'expired'">
                                                <b-badge variant="danger" class="bg-danger">
                                                    Verlopen
                                                </b-badge>
                                            </template>
                                            <template v-if="order.payment_status == 'canceled'">
                                                <b-badge variant="danger" class="bg-danger">
                                                    Geannuleerd
                                                </b-badge>
                                            </template>
                                        </template>
                                    </td>
                                    <td>{{ order.tickets_sold }}</td>
                                    <td>{{ order.created_at|formatDateTime }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-card-body>
        </b-card>
      </b-container>
    </div>
  </template>
  
  <script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'

    export default {
        metaInfo: {
            title: 'Bestellingen',
        },
        components: {},
        data() {
            return {
                event: [],
                orders: [],
            }
        },
        computed: {
            ordersActive() {
                return this.orders.filter(item => (item.payment_status == 'paid' || item.payment_status == 'guest') && !item.canceled_at)
            },
            safeEventName() {
                return String(this.event.name)
                    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
                    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
                    .trim() // trim leading or trailing whitespace
                    .toLowerCase() // convert to lowercase
                    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
                    .replace(/\s+/g, '-') // replace spaces with hyphens
                    .replace(/-+/g, '-'); // remove consecutive hyphens
            }
        },
        methods: {
            ...mapActions(useShopStore, ['setLoading']),
            fetchEvent: function() {
                let id = this.$route.params.id;
                this.setLoading(true);
                this.$axios.get("https://api.tixgo.nl/events/" + id)
                    .then( response => {
                        this.event = response.data;
                        this.setLoading(false);
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            fetchOrders: function() {
                this.setLoading(true);
                this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id + "/orders")
                    .then( response => {
                        this.orders = response.data;
                        this.setLoading(false);
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            goToDetail: function(order) {
                this.$router.push('/manage/orders/' + order.id);
            },
            exportOrders: function() {
                this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id + "/orders/export", { responseType: 'blob' })
                    .then( response => {
                        let url = window.URL.createObjectURL(new Blob([response.data]));
                        let link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.safeEventName + '_orders_export.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            }
        },
        created() {
            this.fetchEvent();
            this.fetchOrders();
        },
    }
</script>